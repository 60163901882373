<template>
  <!--
    Used to edit the comment of a ConfigurationHistory 
  -->
  <div class="configurationHistoryCommentEdit">
    <template>
      <template v-if="configuration">
        <h4 class="m-0 mt-3">
          {{ $t('comment') }}
        </h4>
        <hr class="m-0 mb-3">
        <textarea
          v-model="configuration.comment"
          v-focus
          :class="['form-control', { 'is-invalid': errors.has('Comment') }]"
          placeholder="Plesea add a comment"
        />
        <span
          v-show="errors.has('Name')"
          class="badge badge-danger"
        >{{ errors.first('Name') }}</span>
        <button
          class="btn btn-primary mt-4 float-right"
          @click="updateConfiguration"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('save') }}
          </span>
        </button>
        <div class="clearfix" />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
export default {
  name: "EditConfigurationHistoryComment",
  mixins: [
    errorMixin
  ],
  props: {
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true
    }
  },

  methods: {
    updateConfiguration () {
      let updateCommentDto={
        'currentConfigurationId': this.configuration.currentConfigurationId,
        'comment': this.configuration.comment
      }
      this.axios.post('/Config/UpdateConfigurationHistoryComment', updateCommentDto)
        .then(() => {
            this.$emit('closeSidebar');
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
